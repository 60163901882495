import { template as template_ecd7380910b2476aa4f99947bf0a193e } from "@ember/template-compiler";
const SidebarSectionMessage = template_ecd7380910b2476aa4f99947bf0a193e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
