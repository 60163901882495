import { template as template_0e8d4549a2e44cc9ad94dd866efb0523 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0e8d4549a2e44cc9ad94dd866efb0523(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
