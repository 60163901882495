import { template as template_21e2a5bfadae4038a83497f29002a010 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import { or } from "truth-helpers";
import PluginOutlet from "discourse/components/plugin-outlet";
const GroupsInfo = template_21e2a5bfadae4038a83497f29002a010(`
  <PluginOutlet
    @name="group-info-details"
    @outletArgs={{hash group=@group}}
    @defaultGlimmer={{true}}
  >
    <span class="group-info-details">
      <span class="groups-info-name">
        {{or @group.full_name @group.displayName}}
      </span>
    </span>
  </PluginOutlet>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupsInfo;
